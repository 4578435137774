import React from 'react';
import Link from 'next/link';
import Router from 'next/router';
import { PuffLoader } from 'react-spinners';
import CookieConsent from 'react-cookie-consent';

import NavBar from './NavBar';
import Footer from './Footer';

const Layout = ({
  backLink,
  link,
  isLoading,
  children,
  className = '',
  classNameBodyContainer = '',
}) => (
  <>
    <div className="c-layout">
      <NavBar link={link} backLink={backLink} />
      <div className={`c-body ${className}`}>
        <section className="container">
          <div className={classNameBodyContainer}>{children}</div>
        </section>
      </div>
      <Footer />
      <CookieConsent
        cookieName="acceptCookies"
        location="bottom"
        buttonText="Aceptar"
        enableDeclineButton
        hideOnAccept
        overlay
        flipButtons
        declineButtonText="Ver política de cookies"
        onDecline={() => {
          Router.push('/terms-cookies');
        }}
        style={{
          width: 'calc(100% - 40px)',
          maxWidth: '400px',
          right: '20px',
          left: 'auto',
          borderRadius: '8px',
          backgroundColor: '#eae6e0',
          bottom: '20px !important',
          marginBottom: '20px',
          color: 'black',
        }}
        buttonStyle={{
          color: 'white',
          fontSize: '13px',
          backgroundColor: '#E60C43',
          borderRadius: '5px',
        }}
        declineButtonStyle={{
          background: 'transparent',
          color: '#E60C43',
          padding: '0',
          margin: '0',
        }}
        expires={150}>
        Esta web uliliza cookies para mejorar la experiencia de sus usuarios.{' '}
        Aquí puedes consultar nuestra{' '}
        <Link href="/terms-cookies">
          <a className="c-layout__cookies-link">política de cookies</a>
        </Link>
      </CookieConsent>
    </div>
    {isLoading && (
      <div className="c-layout__spinner">
        <PuffLoader size={60} color="#E60C43" />
      </div>
    )}
  </>
);

export default Layout;
