import Link from 'next/link';

const LinkButton = ({ children, href, onClick, className = '', ...props }) => (
  <>
    {href ? (
      <Link href={href}>
        <a
          className={`c-link ${className}`}
          onClick={onClick}
          role="button"
          {...props}
          href={href}>
          {children}
        </a>
      </Link>
    ) : (
      <button
        className={`c-link ${className}`}
        onClick={onClick}
        type="button"
        {...props}>
        {children}
      </button>
    )}
  </>
);

export default LinkButton;
