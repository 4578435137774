import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { addDays } from 'date-fns';

import FeedEvents from '../components/events/FeedEvents';
import SearchBar from '../components/searchBar/SearchBar';
import Layout from '../layout/Layout';
import { getEvents } from '../redux/events/actions';

const ITEMS_PER_PAGE = 8;

const Agenda = () => {
  const [page, setPage] = useState(1);

  const { total, events } = useSelector((state) => state.events);
  const isPendingEvents = useSelector((state) => state.events.isLoadingEvents);
  const filters = useSelector((state) => state.events.filters);

  const { handleSubmit, control, reset, setValue } = useForm();

  const dispatch = useDispatch();

  useEffect(() => {
    const dateRange = [new Date(), addDays(new Date(), 60)];
    reset({
      dateRange,
      eventCategories: [],
      eventType: '',
      price: [0, 200],
      query: '',
    });

    (async () => {
      await dispatch(
        getEvents({ limit: ITEMS_PER_PAGE, page, filters: { dateRange } })
      );
    })();
  }, []);

  const onSubmit = handleSubmit(async (data) => {
    dispatch(
      getEvents({ limit: ITEMS_PER_PAGE, page: 1, filters: { ...data } })
    );
  });

  const handlePagination = async (p) => {
    setPage(p);
    await dispatch(getEvents({ limit: ITEMS_PER_PAGE, page: p, filters }));
  };

  const clearFilters = () => {
    setValue('eventCategories', []);
    setValue('eventType', '');
    setValue('price', [0, 200]);
    setValue('municipios', null);
  };

  return (
    <Layout link="acceder" backLink="elsports" isLoading={isPendingEvents}>
      <div className="c-agenda">
        <div className="c-agenda__feed">
          <SearchBar
            control={control}
            clearFilters={clearFilters}
            handleSubmit={onSubmit}
          />

          <FeedEvents
            events={events}
            page={page}
            count={Math.ceil(total / ITEMS_PER_PAGE)}
            onChange={(e, p) => handlePagination(p)}
          />
        </div>
      </div>
    </Layout>
  );
};

export default Agenda;
