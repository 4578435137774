const NoResults = ({
  title = '¡Ups! no hay resultados',
  subtitle = 'No hemos encontrado ningún evento',
  body = 'No existen eventos con la combinación de filtros introducida, prueba a modificarlos o asegurate de que los has introducido correctamente.',
}) => (
  <div className="c-no-results">
    <img alt="no hay eventos" src="/img/icons/search.svg"></img>
    <h3>{title}</h3>
    <span>{subtitle}</span>
    <p>{body}</p>
  </div>
);

export default NoResults;
