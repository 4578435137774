import { useController } from 'react-hook-form';

import OptionButton from '../buttons/OptionButton';
import MaterialInputDiv from './MaterialInputDiv';

const ControlledMultipleButtonGroup = ({
  control,
  name,
  rules,
  defaultValue,
  label,
  options,
  error,
  getLabel,
  className,
  color,
}) => {
  const {
    field: { ref, value, onChange },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  });

  return (
    <MaterialInputDiv
      label={label}
      error={error}
      className={`${className} ${color && `c-input-Mui--${color}`}`}>
      {options.map((option) => (
        <OptionButton
          key={getLabel(option)}
          ref={ref}
          isActive={getLabel(value ? value : { type: '' }) === getLabel(option)}
          onClick={() => {
            onChange(option);
          }}>
          {getLabel(option)}
        </OptionButton>
      ))}
    </MaterialInputDiv>
  );
};

export default ControlledMultipleButtonGroup;
