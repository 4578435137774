import Link from 'next/link';

const Footer = () => (
  <section className="c-footer">
    <span className="c-footer__title">
      Plan de Dinamización y Gobernanza turística Els Ports
    </span>
    <img
      className="c-footer__image"
      src="/img/plan-de-gov-turistica-els-ports.svg"
      alt="Qué hacer en Els Ports"
    />
    <div className="c-footer__help">
      <Link href="/ayuda">
        <a>Sección de ayuda</a>
      </Link>
      <span> | </span>
      <Link href="/terms-cookies">
        <a>política de privacidad</a>
      </Link>
    </div>
  </section>
);
export default Footer;
