import { Chip } from '@material-ui/core';
import { CloseRounded } from '@material-ui/icons';

const Tag = ({
  icon,
  extraClass = '',
  hideIcon = false,
  onClick = () => {},
  tag,
  ...props
}) => (
  <Chip
    {...props}
    onClick={() => {
      tag?.tagTypeName !== 'Text' && onClick(tag);
    }}
    classes={{
      root: `c-tag c-tag--${icon} ${extraClass}`,
    }}
    deleteIcon={
      hideIcon ? null : <CloseRounded classes={{ root: 'c-tag__close-icon' }} />
    }
  />
);

export default Tag;
