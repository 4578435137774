import { BeatLoader } from 'react-spinners';
import Router from 'next/router';

const Button = ({
  children,
  className = '',
  isLoading,
  href,
  isActive = '',
  onClick,
  disabled = false,
  ...props
}) => (
  <button
    type="button"
    className={`c-button ${className} ${isActive && 'c-button--active'} ${
      disabled && 'c-button--disabled'
    } `}
    onClick={() => {
      if (!disabled) {
        if (onClick) {
          onClick();
        } else {
          href && Router.push(href);
        }
      }
    }}
    {...props}>
    {isLoading ? <BeatLoader color="#eae6e0" size={10} /> : children}
  </button>
);
export default Button;
