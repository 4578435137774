const MaterialInputDiv = ({ children, label, className = '', error }) => (
  <>
    <div className={`c-material-input-div ${className}`}>
      <span className="c-material-input-div__label">{label}</span>
      {children}
    </div>
    {error && (
      <span className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error c-material-input-div__error">
        {error.message}
      </span>
    )}
  </>
);

export default MaterialInputDiv;
