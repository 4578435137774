import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useController } from 'react-hook-form';

const ControlledSingleSelect = ({
  control,
  name,
  rules,
  defaultValue,
  variant,
  error,
  color,
  label,
  options,
  getLabel,
  className = '',
  ...props
}) => {
  const {
    field: { value = [], onChange },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  });

  return (
    <div className="c-multiple-select">
      <Autocomplete
        {...props}
        className={`${className} ${color && `c-input-Mui--${color}`}`}
        options={options}
        error={error}
        fullWidth
        value={value || []}
        onChange={(e, newValue) => {
          onChange(newValue);
        }}
        disableCloseOnSelect
        getOptionLabel={getLabel}
        renderOption={(option) => <span>{getLabel(option)}</span>}
        style={{ width: '100%' }}
        renderInput={(params) => (
          <div>
            <TextField {...params} variant={variant} label={label} fullWidth />
          </div>
        )}
      />
    </div>
  );
};

export default ControlledSingleSelect;
