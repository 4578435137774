import { format } from 'date-fns';
import { es } from 'date-fns/locale';

const Calendar = ({ date }) => (
  <div className="c-calendar">
    <span className="c-calendar__one">{format(date, 'dd')}</span>
    <span className="c-calendar__two">
      {format(date, 'iii', {
        locale: es,
      }).toUpperCase()}
    </span>
  </div>
);

export default Calendar;
