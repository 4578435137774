import { TextField } from '@material-ui/core';
import { useController } from 'react-hook-form';

function ControlledInput({
  control,
  name,
  rules,
  error = false,
  defaultValue,
  InputProps,
  className,
  color = '',
  ...props
}) {
  const {
    field: { ref, value, ...inputProps },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  });

  return (
    <TextField
      {...inputProps}
      {...props}
      className={`${className} ${color && `c-input-Mui--${color}`}`}
      value={value || ''}
      error={error ? true : false}
      helperText={error ? error.message : ''}
      inputRef={ref}
    />
  );
}
export default ControlledInput;
