import ReactTooltip from 'react-tooltip';
import Tag from '../inputs/Tag';

const EventPrice = ({ tickets, enableTooltip = false }) => {
  const allEqual = (arr) => arr.every((val) => val === arr[0]);

  const getPriceRange = () => {
    if (tickets?.length > 1) {
      const pricesArray = tickets.map((ticket) => ticket.price);

      if (allEqual(pricesArray)) {
        return `${pricesArray[0]}€`;
      }
      return `${Math.min(...pricesArray)}€ - ${Math.max(...pricesArray)}€`;
    }
    return `${tickets[0].price}€`;
  };

  return (
    <>
      <div className="c-card-event__price" data-tip data-for="price-tooltip">
        {tickets.length ? getPriceRange(tickets) : '€ Gratuito'}
      </div>
      {tickets.length && enableTooltip ? (
        <ReactTooltip
          id="price-tooltip"
          backgroundColor="#fcfcfc"
          border={1}
          borderColor="rgba(0,0,0,0.4)"
          effect="solid"
          className="c-card-event__price-tooltip">
          {tickets.map(({ id, name, price }) => (
            <Tag
              extraClass="c-card-event__price-tag"
              key={id}
              label={`${name} - ${price}€`}
            />
          ))}
        </ReactTooltip>
      ) : (
        ''
      )}
    </>
  );
};

export default EventPrice;
