import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { useController } from 'react-hook-form';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const ControlledMultipleSelect = ({
  control,
  name,
  rules,
  defaultValue,
  variant,
  error,
  color,
  label,
  options,
  getLabel,
  className = '',
  ...props
}) => {
  const {
    field: { value = [], onChange },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  });

  return (
    <div className="c-multiple-select">
      <Autocomplete
        {...props}
        className={`${className} ${color && `c-input-Mui--${color}`}`}
        multiple
        options={options}
        error={error}
        fullWidth
        value={value || []}
        onChange={(e, newValue) => {
          onChange(newValue);
        }}
        disableCloseOnSelect
        getOptionLabel={getLabel}
        renderOption={(option, { selected }) => (
          <>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={
                value.find((opt) => getLabel(option) === getLabel(opt)) ||
                selected
              }
            />
            {getLabel(option)}
          </>
        )}
        style={{ width: '100%' }}
        renderInput={(params) => (
          <div>
            <TextField {...params} variant={variant} label={label} fullWidth />
          </div>
        )}
      />
    </div>
  );
};

export default ControlledMultipleSelect;
