import { useState } from 'react';

import { Search } from '@material-ui/icons';
import { useSelector, useDispatch } from 'react-redux';
import { BeatLoader } from 'react-spinners';

import ControlledInput from '../inputs/ControlledInput';
import Button from '../buttons/Button';
import RangePicker from '../inputs/RangePicker';
import ControlledMultipleButtonGroup from '../inputs/ControlledMultipleButtonGroup';
import { getOptions } from '../../redux/search/actions';
import ControlledMultipleSelect from '../inputs/ControlledMultipleSelect';
import ControlledSingleSelect from '../inputs/ControlledSingleSelect';

const SearchBar = ({ control, handleSubmit, clearFilters }) => {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const areOptionsLoading = useSelector(
    (state) => state.search.isLoadingSearch
  );
  const searchOptions = useSelector((state) => state.search.options);

  const dispatch = useDispatch();

  const handleOpenFilter = () => {
    setIsFilterOpen(!isFilterOpen);
    if (Object.keys(searchOptions).length === 0) {
      dispatch(getOptions());
    }
  };

  return (
    <form className="c-search-bar" onSubmit={handleSubmit}>
      <div className="c-search-bar__main">
        <ControlledInput
          control={control}
          name="query"
          variant="outlined"
          label="Buscar"
        />
        <RangePicker
          control={control}
          name="dateRange"
          containerClassName="c-search-bar__date-picker-container"
          className="c-search-bar__date-picker"
        />
        <Button
          className="c-search-bar__filter-button"
          onClick={() => handleOpenFilter(!isFilterOpen)}>
          <div
            className={`c-search-bar__filter-icon ${
              isFilterOpen ? 'c-search-bar__filter-icon--active' : ''
            }`}
          />
        </Button>
        <Button
          isActive
          className="c-search-bar__button"
          type="submit"
          onClick={() => {}}>
          <Search style={{ fontSize: '2rem' }} />
        </Button>
      </div>
      {isFilterOpen && (
        <div className="c-search-bar__filters">
          {areOptionsLoading ? (
            <div className="c-search-bar__filters-loader">
              <BeatLoader color="#e51d43" size={10} />
            </div>
          ) : (
            <>
              <div className="c-search-bar__categories">
                <div className="c-search-bar__categories-input">
                  <ControlledMultipleSelect
                    color="light-grey"
                    control={control}
                    name="eventCategories"
                    label="Categoría"
                    variant="outlined"
                    options={searchOptions.categories}
                    getLabel={(item) => item.category}
                  />
                </div>
                <div className="c-search-bar__municipios-input">
                  <ControlledSingleSelect
                    color="light-grey"
                    control={control}
                    name="municipio"
                    label="Municipio"
                    variant="outlined"
                    options={searchOptions.municipios}
                    getLabel={(item) => item.label}
                  />
                </div>
              </div>
              <div className="c-search-bar__format-container">
                <ControlledMultipleButtonGroup
                  control={control}
                  color="light-grey"
                  name="eventType"
                  label="Formato"
                  variant="outlined"
                  className="c-search-bar__format"
                  getLabel={(item) => item.type}
                  options={searchOptions?.eventTypes || []}
                />
              </div>

              <Button
                className="c-search-bar__clear-filter-button"
                onClick={() => clearFilters()}>
                <div className="c-search-bar__clear-filter-icon" />
              </Button>
            </>
          )}
        </div>
      )}
    </form>
  );
};

export default SearchBar;
