import { useController } from 'react-hook-form';
import { Application, DatePicker } from 'react-rainbow-components';
import { useMediaQuery } from 'react-responsive';

const RangePicker = ({
  name,
  control,
  className = '',
  containerClassName = '',
  ...props
}) => {
  const {
    field: { value, onChange },
  } = useController({ name, control });

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 800px)' });

  const theme = {
    rainbow: {
      palette: {
        brand: '#E60C43',
      },
    },
  };

  return (
    <Application theme={theme} className={containerClassName}>
      <DatePicker
        {...props}
        className={`c-range-picker ${className}`}
        value={value}
        onChange={onChange}
        label="Fechas"
        formatStyle="medium"
        locale="es-ES"
        selectionType="range"
        variant={isTabletOrMobile ? 'single' : 'double'}
      />
    </Application>
  );
};

export default RangePicker;
