import React from 'react';
import Router from 'next/router';
import Link from 'next/link';
import jsCookie from 'js-cookie';
import axios from 'axios';
import { useDispatch } from 'react-redux';

import Button from '../components/buttons/Button';
import { logout } from '../redux/user/actions';

const NavBar = ({ link, backLink = 'home' }) => {
  const dispatch = useDispatch();

  const NAV_LINKS = {
    acceder: {
      string: 'Acceder',
      action: async () => {
        const token = await jsCookie.get('token');
        if (token) {
          Router.push('/intranet');
        } else {
          Router.push('/login');
        }
      },
    },
    salir: {
      string: 'Salir',
      action: () => {
        jsCookie.remove('token');
        Router.push('/');
        axios.defaults.headers.authorization = '';
        dispatch(logout());
      },
    },
  };

  const BACK_LINKS = {
    home: {
      string: 'Volver a la agenda virtual',
      url: '/',
    },
    elsports: {
      string: 'Volver a elsports.es',
      url: 'http://elsports.es',
    },

    intranet: {
      string: 'Volver a la intranet',
      url: '/intranet',
    },
  };

  return (
    <nav className="c-nav">
      <div className="container">
        <div className="c-nav__container">
          <div className="c-nav__left-container">
            <img
              onClick={() => {
                Router.push('/');
              }}
              className="c-nav__logo"
              src="/img/logo-els-ports.svg"
              alt="agenda els ports es autèntic"
              style={{ width: '300px', height: '60px' }}
            />
          </div>
          {link && (
            <Button onClick={NAV_LINKS[link].action}>
              {NAV_LINKS[link].string}
            </Button>
          )}
        </div>
        <Link href={BACK_LINKS[backLink].url}>
          <a className="c-nav__back-link">{BACK_LINKS[backLink].string}</a>
        </Link>
        <h1 className="c-nav__title">Agenda Virtual dels Ports</h1>
      </div>
    </nav>
  );
};

export default NavBar;
