export const lengthSlicer = (string, length) => {
  if (string && string.length > length) {
    return `${string.slice(0, length)}...`;
  }
  return string;
};

export const localityTransformNames = (loc) => {
  if (loc?.toLowerCase() === 'portell de morella') return 'Portell';
  if (loc?.toLowerCase() === 'la mata de morella') return 'La Mata';

  return loc;
};
