import Pagination from '@material-ui/lab/Pagination';
import { isBefore, format, getYear } from 'date-fns';
import { es } from 'date-fns/locale';
import _ from 'lodash';
import Separator from '../ui/Separator';

import CardEvent from './CardEvent';
import NoResults from '../ui/NoResults';

const FeedEvents = ({
  events,
  isIntranet,
  onEventClick,
  noResultsMessages,
  ...pagination
}) => {
  const groupByMonth = (array) => {
    const getMonthName = (date) =>
      _.capitalize(
        format(new Date(date), 'LLLL', {
          locale: es,
        })
      );

    const groups = _.groupBy(array, (event) => getMonthName(event.initDate));

    return Object.keys(groups).map((month) => ({
      date: `${month} ${getYear(new Date(groups[month][0].initDate))}`,
      groupedEvents: groups[month],
    }));
  };

  const checkIsOutDated = (event) => {
    if (
      isBefore(new Date(event.finishDate), new Date()) &&
      isBefore(new Date(event.initDate), new Date())
    ) {
      return true;
    }
    return false;
  };

  return (
    <>
      <div
        className={`c-feed-events ${
          events?.length ? 'c-feed-events' : 'c-feed-events--align-center'
        }`}>
        {groupByMonth(events)?.map(({ date, groupedEvents }) => (
          <div key={date} className="c-feed-events__month-container">
            <Separator>{date}</Separator>
            {groupedEvents.map((event) => (
              <CardEvent
                onEventClick={onEventClick}
                event={event}
                key={event.id}
                isIntranet={isIntranet}
                isOutDated={checkIsOutDated(event)}
              />
            ))}
          </div>
        ))}
        {events?.length === 0 && <NoResults {...noResultsMessages} />}
      </div>
      {events?.length ? <Pagination {...pagination} /> : ''}
    </>
  );
};
export default FeedEvents;
