/* eslint-disable jsx-a11y/no-static-element-interactions */
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import HTMLParser from 'html-react-parser';

import LinkButton from '../buttons/LinkButton';
import { lengthSlicer } from '../../utils/formatters';
import { getImage } from '../../utils/event';

import Calendar from './Calendar';
import EventPrice from './EventPrice';
import { setFocusedEvent } from '../../redux/events/actions';
import { getLocality } from '../../utils/location';
import Tag from '../inputs/Tag';

const CardEvent = ({ isIntranet, event, isOutDated }) => {
  const {
    title,
    eventTickets,
    description,
    id,
    image,
    initDate,
    finishDate,
    location,
    onEventClick,
    isValidated,
    municipio,
    eventCategories,
  } = event;
  const init = new Date(initDate);
  const finish = new Date(finishDate);

  const router = useRouter();
  const dispatch = useDispatch();

  const handleEdit = () => {
    dispatch(setFocusedEvent(event));
    router.push(`/edit-event/${id}`);
  };

  return (
    <article
      className={`c-card-event ${isOutDated ? 'c-card-event--outdated' : ''}`}>
      <Calendar date={init} />
      <div className="c-card-event__info-container">
        <span className="c-card-event__date">
          {`${format(init, 'dd MMMM yyyy', {
            locale: es,
          })} a las ${format(init, 'hh:mm')} - ${format(
            finish,
            'dd MMMM yyyy',
            {
              locale: es,
            }
          )}  a las ${format(finish, 'hh:mm')}`}{' '}
        </span>
        {onEventClick ? (
          <h3
            className="c-card-event__title"
            onClick={() => onEventClick(event)}>
            {title}
          </h3>
        ) : (
          <Link href={`/event/${id}`}>
            <a>
              <h3 className="c-card-event__title">{title}</h3>
            </a>
          </Link>
        )}
        {isIntranet && !isValidated && (
          <Tag
            extraClass="c-card-event__title--pending-tag"
            label="Pendiente de validar"
          />
        )}

        <div className="c-card-event__details-container">
          <span className="c-card-event__categories">
            {eventCategories.map(({ category, id: categoryId }) => (
              <Tag key={categoryId} label={category} />
            ))}
          </span>
          <span className="c-card-event__address">
            {municipio ? municipio.label : getLocality(location)}
          </span>
        </div>
        <div className="c-card-event__description">
          {HTMLParser(lengthSlicer(description, 280))}
        </div>
        <EventPrice tickets={eventTickets} />
        {isIntranet ? (
          <LinkButton onClick={handleEdit}>Editar evento</LinkButton>
        ) : (
          <LinkButton href={`/event/${id}`}>Ver más</LinkButton>
        )}
      </div>
      <div
        className="c-card-event__image"
        onClick={() => {
          router.push(`/event/${id}`);
        }}
        style={{
          background: `url(${getImage(image)})`,
        }}></div>
    </article>
  );
};

export default CardEvent;
